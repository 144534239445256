.mobile-menu-wrap {
  background-color: $bg-light2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  max-width: 600px;
  z-index: 997;
  padding: 80px 30px 30px;
  overflow-y: auto;
  transition: all 0.3s;
  transform: translateX(100%);
  &.show {
    transform: none;
  }
  .navbar-1 {
    padding-bottom: 50px;
  }
  .navbar-nav {
    text-align: left;
    .nav-item  {
      border-bottom: 1px solid rgba($color: $bg-dark, $alpha: 0.1);
      display: flex;
      //align-items: center;
      flex-wrap: wrap;
      position: relative;
      .tag {
        order: 0;
        background: $primary;
        color: $white;
        font-size: 0.9rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 2px 5px;
        align-self: center;
        margin-left: 15px;
      }
    }
    .has-children {
      position: relative;
      .show-submenu {
        position: absolute;
        padding: 15px 0;
        right: 5px;
        &::after {
          content: '\f067'; 
          font-family: 'icomoon';
        }
        &.active {
          &::after {
            content: '\f068';
            color: $primary;
          }
        }
      }
    }
    .nav-link {
      color: $text;
      font-weight: 500;
      font-size: 1.4rem;
      padding: 15px 0;
      order: -1;
      
    }
    .sub-menu {
      display: none;
      list-style: none;
      padding-left: 10px;
      flex: 0 0 100%;
      .nav-item {
        border-bottom: none;
        .nav-link {
          padding: 10px 0;
          font-weight: 500;
          color: rgba($color: $text, $alpha: 0.5);
        }
        &.active {
           >.nav-link {
            color: $text;
            font-weight: bold;
          }
        }
      }
    }
    .megamenu {
      >.sub-menu {
        padding: 0;
        >.nav-item {
          padding: 0;
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0 20px;
          background-color: $white;
          margin-bottom: 10px;
          .sub-menu {
            display: block !important;
            flex: 0 0 100%;
            position: static;
            box-shadow: none;
            padding: 5px 0px;
            border-top: 1px solid rgba($color: #333, $alpha: 0.1);
            min-width: 0;
            .nav-item {
              &.active {
                >.nav-link {
                  color: rgba($color: $text, $alpha: 0.5);
                 font-weight: bold;
               }
             }
            }
          }
          &.category {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .tag {
              background: $black;
              text-transform: none;
            }
            img {
              display: block;
              max-width: 23px;
              max-height: 23px;
              margin-top: 13px;
              margin-left: auto;
            }
            >.nav-link {
              cursor: default;
              flex: 0 0 0;
              font-size: 1.4rem;
              color: $text;
              font-weight: bold;
              padding: 15px 0px;
            }
            .show-submenu {
              display: none;
            }
          }
        }
        
      }
    }
    .active {
      > .nav-link {
          font-weight: bold;
      }
    }
  }
  .buttons-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      margin-bottom: 20px;
    }
  }
  .social-ikons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      color: $text;
      width: 100px;
      height: 100px;
      background-color: $white;
      margin: 5px;
      i {
        font-size: 2rem;
      }
      span {
        font-size: 1.4rem;
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    display: none;
  }
}