
//
// Color system
//

// stylelint-disable
$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$text: #333333;
$text-light: #F8F8F8;
$bg-light: #F4F4F5;
$bg-light2: #F8F8F8;
$bg-dark: #222222;
$bg-dark2: #333333;

$white-light: #FCFDFD;
$white-normal: #F9FAFB;
$white-normal-active: #EBEEF2;


$blue:    #0082E1;
$blue-light: #339DFF;
$indigo:  #6610f2;
$purple:  #c9b0d2;
$pink:    #EB7891;
$pink-dark:    #DA7E92;
$red-light:     #EB5757;
$red:     #E10600;
$orange:  #FFD275;
$yellow:  #F3EB33;
$green:   #5AC826;
$turquose:   #1D758D;
$green-light: #0FF571;
$teal:    #20c997;
$cyan:    #17a2b8;
$brown: #412d32;
$gray-dark: #7F7B82;
$gray-light: #F5F6F8;
$gold: #A49478;

$brown-50: #EEEBEA;
$brown-100: #CAC2BD;
$brown-200 : #B1A49D;
$brown-300 : #8D7A70;
$brown-400 : #776155;
$brown-500: #55392A;
$brown-600: #4D3426;
$brown-700: #3C281E;
$brown-900: #241812;

$green-50: #F0F7EC;
$green-100: #cfe7c4;
$green-500: #64B341;
$green-600: $green;
$green-700: #477F2E;
$green-800: #376224;
$green-900: #004B1F;

$warning-light : #fcf8eb;
$warning-normal-active: #B5942F;
$warning-normal-hover: #CBA735;
$warning-dark-hover: #886F23;

$info-light: #EAF2FD;
$info-normal: #2F80ED;

$surface-normal-active: #EBEEF2;
$surface-normal: #F9FAFB;

$surface-light: #FCFDFD;


$primary:       $pink;
$secondary:     $green;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $gray-900;



// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1.6rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        inherit;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              inherit;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.25);

$hr-border-color:             rgba($black, .1);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;




$form-label-margin-bottom: 8px;
$form-label-font-weight: 500;

$input-btn-padding-y:         9px;
$input-btn-padding-x:         18px;
$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;


$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
//$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
//$input-line-height:                     $input-btn-line-height;

$input-bg:                              $surface-light;
$input-disabled-color:                  null;
$input-disabled-bg:                     $gray-200;
$input-disabled-border-color:           null;

$input-color:                           $body-color;
$input-border-color:                    $brown-100;

$enable-shadows: false;
$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary;
$input-focus-color:                     $input-color;
//$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                none;

$input-placeholder-color:               $gray-600;
$input-plaintext-color:                 $body-color;

$form-select-focus-box-shadow : none;
$form-check-input-focus-box-shadow: none;