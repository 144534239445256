.hp-top-category {
  padding: 60px 0;
  h2 {
    letter-spacing: 6.6px;
  }
  p {
    line-height: 32px;
    color: rgba($color: $text, $alpha: 0.5);

  }
  .top-category-wrap {
    margin-top: 20px;
  }
  .top-category-item {
    background-color: #F4F4F5;
    padding: 15px 0;
    text-align: center;
    display: block;
    margin-bottom: 12px;
    h4 {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: normal;
      transition: all 0.3s;
    }
    &:hover {
      text-decoration: none;
      h4 {
        color: $primary;
      }
    }
  }
  @include media-breakpoint-up(lg) { 
    padding-bottom: 100px;
    h2 {
      letter-spacing: 10.8px;
    }
    .top-category-wrap {
      margin-top: 40px;
    }
    .top-category-item {
      padding: 30px 0;
    }
  }
}