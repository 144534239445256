.hp-slider {
  background-color: #F8F8F8;
  @include media-breakpoint-up(lg) { 
    padding: 20px;
    padding-bottom: 50px;
  }
  .swiper-slide {
    position: relative;
    height: auto;
    @include media-breakpoint-up(lg) { 
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .content-wrap {
    padding: 240px 0 50px;
    z-index: 2;
    position: relative;
    .title {
      color: $white;
      text-shadow: 0px 3px 6px #000000;
      text-transform: uppercase;
      letter-spacing: 6.6px;
      font-size: 2.2rem;
      margin-bottom: 15px;
    }
    .subtitle {
      color: rgba($color: $white, $alpha: 0.76);
      //text-shadow: 0px 3px 6px #000000;
      letter-spacing: 0.7px;
      line-height: 32px;
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(lg) { 
      padding: 360px 0 150px;
      
      .content-text {
        max-width: 550px;
      }
      .title {
        margin-bottom: 30px;
        font-size: 3.6rem;
        letter-spacing: 10.8px;
      }
      .subtitle {
        color: rgba($color: $white, $alpha: 0.9);
        letter-spacing: 0.8px;
        //text-shadow: none;
        font-size: 1.6rem;
        margin-bottom: 30px;
      }
    }
  }

  .swiper-pagination {
    top: 5px;
    left: 5px;
    width: auto;
    display: flex;
    flex-direction: column;
    bottom: initial;
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: rgba($color: $white, $alpha: 0.3);
      margin: 5px 0;
      &.swiper-pagination-bullet-active {
        background-color: $white;
      }
    }
    @include media-breakpoint-up(lg) { 
      flex-direction: row;
      align-items: center;
      justify-content: center;
      top: initial;
      bottom: 20px;
      left: 0;
      right: 0;
      .swiper-pagination-bullet {
        margin: 0 5px;
      }
    }
  }
}