.product-categories {
  .navbar-nav {
    .nav-item {
      .nav-link  {
        padding: 7px 0;
        font-size: 1.4rem;
        color: $text;
        font-weight: bold;
      }
      .sub-menu {
        display: none;
      }
      &.active {
        >.nav-link  {
          position: relative;
          &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 20px;
            height: 3px;
            background-color: $primary;
          }
        }
        .sub-menu {
          display: block;
        }
      }
    }
  }
  .sub-menu {
    list-style: none;
    padding-left: 10px;
    .nav-item {
      .nav-link  {
        font-weight: normal;
      }
      &.subactive {
        >.nav-link {
          font-weight: bold;
        }
      }
    }
    .category {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 10px;
      background-color: $bg-light2;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: -10px;
      img {
        display: block;
        max-width: 23px;
        max-height: 23px;
        margin-top: 13px;
      }
      >.nav-link {
        flex: 1 0 0;
        font-size: 1.4rem;
        color: $text;
        font-weight: bold;
        padding: 15px 0px;
      }
      >.sub-menu {
        display: block !important;
        flex: 0 0 100%;
        position: static;
        box-shadow: none;
        padding: 5px 0px;
        border-top: 1px solid rgba($color: #333, $alpha: 0.1);
        min-width: 0;
        .nav-item {
          >.nav-link {
            &:hover {
              color: $primary;
            }
          }
          &.subsubactive {
            >.nav-link {
              color: rgba($color: $text, $alpha: 0.5);
             font-weight: bold;
           }
         }
        }
      }
    }
  }
}