
.cart-table {
  th {
    font-weight: bold;
  }
  tbody {
    tr {
      th, td {
        vertical-align: middle;
      }
    }
  }
  .product-thumbnail {
    a {
      display: block;
      width: 55px;
    }
    img {
      width: 55px;
    }
  }
  .product-name {
    a {
      color: $text;
      &:hover {
        color: $primary;
      }
    }
  }
  .actions {
    text-align: right;
    .coupon {
      float: left;
      display: flex;
      label {
        display: none;
      }
      .form-control {
        margin-right: 10px;
      }
      .btn {
        flex: 0 0 auto;
      }
    }
  }
}

.totals-table {
  th {
    font-weight: bold;
  }
  .woocommerce-shipping-methods {
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      label {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}


.woocommerce-checkout-payment {
  .wc_payment_methods {
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .payment_box {
        flex: 0 0 100%;
        margin-top: 5px;
        p {
          font-size: 1.2rem;
        }
      }
      label {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}

.woocommerce-order-overview {
  list-style: none;
  padding-left: 0;
}