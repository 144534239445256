.product-item {
  margin-bottom: 20px;
  .image-wrap {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    padding-top: 133%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s;
    }
    &:hover {
      
      img {
        transform: scale(1.05);
      }
    }
    .salebadge {
      position: absolute;
      left: 0;
      top: 30px;
      background-color: $primary;
      color: $white;
      padding: 5px 10px;
      font-size: 1.4rem;
      font-weight: bold;
      z-index: 2;
    }
    .backorder {
      position: absolute;
      right: 0;
      bottom: 20px;
      background-color: #F0A075;
      color: $white;
      padding: 5px 10px;
      //border-radius: 4px;
      font-size: 0.8rem;
      font-weight: bold;
      @include media-breakpoint-up(md) { 
        font-size: 1rem;
      }
    }
    .categorybadge {
      position: absolute;
      top: 20px;
      left: 0;
      background-color: $black;
      color: $white;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 5px 10px;
      &.withsale {
        top: 50px;
      }
      @include media-breakpoint-up(md) { 
        top: 40px;
        font-size: 1.2rem;
      }
      &.withsale {
        top: 70px;
      }
    }
  }
  .content-wrap {
    display: block;
    padding: 10px ;
    text-decoration: none;
    h3 {
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    .price {
      font-size: 1.4rem;
      font-weight: 600;
      color: $text;
      ins {
        color: $primary;
        font-weight: bold;
        text-decoration: none;
      }
    }
    .stock {
      font-size: 1.4rem;
      font-weight: 600;
      color: $text;
      &.stock-empty {
        color: $red;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  
}

.product-item-promo {
  width: 100%;
  background-color: $bg-dark2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 133%;
  .product-promo-text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .subtitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: rgba($color: $text-light, $alpha: 0.5);
    letter-spacing: 4.8px;
  }
  .title {
    color: $text-light;
    font-size: 2.2rem;
    line-height: 1.5;
    letter-spacing: 7.8px;
    margin-bottom: 30px;
    text-align: center;
  }
  @include media-breakpoint-up(lg) { 
    .subtitle {
      font-size: 1.6rem;
    }
    .title {
      font-size: 2.5rem;
      letter-spacing: 8.8px;
    }
  }
  @include media-breakpoint-up(xxl) { 
    .subtitle {
      font-size: 1.6rem;
    }
    .title {
      font-size: 3.6rem;
      letter-spacing: 10.8px;
    }
  }
}