.product-archive {
  padding: 15px 0 60px;
  .product-category-header {
    display: none;
  }
  .breadcrumbs {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: $text;
    .devider {
      color: $primary;
    }
    a {
      color: $text;
      font-size: 1.2rem;
      &:hover {
        color: $primary;
      }
    }
  }
  h1 {
    margin-bottom: 12px;
  }
  .socials-wrap {
    margin: 30px 0;
  }
  @include media-breakpoint-up(md) { 
    .product-category-header {
      display: block;
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 50px 0 100px;
    h1 {
      margin-bottom: 20px;
    }
  }
}

.mobile-pannel-wrap {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bg-light2;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mobile-panel-category {
    cursor: pointer;
    .subtitle {
      font-size: 1rem;
    }
    .title {
      font-weight: bold;
      font-size: 1.4rem;
      i {
        margin-left: 5px;
      }
    }
  }
  .filter {
    cursor: pointer;
    font-size: 1.4rem;
    i {
      margin-right: 5px;
    }
  }
  @include media-breakpoint-up(md) { 
    display: none;
  }
}

.product-filter-wrap {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  .filter-attributes-wrap {
    width: 100%;
    form {
      margin-bottom: 0;
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .reset-filter-wrap {
    a {
      font-size: 1.4rem;
      font-weight: 600;
      color: $text;
      text-decoration: underline;
      &:hover {
        color: $primary;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    .reset-filter-wrap {
      flex: 0 0 115px;
      width: 115px;
    }
  }
}


.mobile-category-wrap {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh - 60px);
  background-color: $bg-light2;
  padding: 20px 15px 50px;
  overflow: auto;
  .mobile-category-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.1);
    padding-bottom: 20px;
    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.6rem;
    }
    .close {
      font-size: 2rem;
    }
  }
  .mobile-category-list {
    .navbar-nav {
      text-align: left;
      .nav-item  {
        border-bottom: 1px solid rgba($color: $bg-dark, $alpha: 0.1);
        display: flex;
        //align-items: center;
        flex-wrap: wrap;
      }
      .has-children {
        position: relative;
        .show-submenu {
          position: absolute;
          padding: 15px 0;
          right: 5px;
          &::after {
            content: '\f067'; 
            font-family: 'icomoon';
          }
          &.active {
            &::after {
              content: '\f068';
              color: $primary;
            }
          }
        }
      }
      .nav-link {
        color: $text;
        font-weight: bold;
        font-size: 1.4rem;
        padding: 15px 0;
        
      }
      .sub-menu {
        display: none;
        list-style: none;
        padding-left: 10px;
        flex: 0 0 100%;
        .nav-item {
          border-bottom: none;
          .nav-link {
            padding: 10px 0;
            font-weight: 600;
            color: rgba($color: $text, $alpha: 0.5);
          }
          &.subactive {
            .nav-link {
              color: $text;
            }
          }
        }
        .category {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 0 10px;
          background-color: $white;
          margin-bottom: 10px;
          margin-top: 10px;
          margin-left: -10px;
          img {
            display: block;
            max-width: 23px;
            max-height: 23px;
            margin-top: 13px;
          }
          >.nav-link {
            flex: 1 0 0;
            font-size: 1.4rem;
            color: $text;
            font-weight: bold;
            padding: 15px 0px;
          }
          >.sub-menu {
            display: block !important;
            flex: 0 0 100%;
            position: static;
            box-shadow: none;
            padding: 5px 0px;
            border-top: 1px solid rgba($color: #333, $alpha: 0.1);
            min-width: 0;
            .nav-item {
              >.nav-link {
                &:hover {
                  color: $primary;
                }
              }
              &.subsubactive {
                >.nav-link {
                  color: rgba($color: $text, $alpha: 0.5);
                 font-weight: bold;
               }
             }
            }
          }
        }
      }
      .active {
        > .nav-link {
            font-weight: bold;
        }
        .sub-menu {
          display: block;
        }
      }
    }
  }
}

.mobile-filter-wrap {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 90vh;
  background-color: $bg-light2;
  padding: 20px 15px 50px;
  overflow: auto;
  .mobile-category-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.1);
    padding-bottom: 20px;
    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.6rem;
    }
    .close {
      font-size: 2rem;
    }
  }
  .filter-attributes-wrap {
    padding: 20px 0 0;
    .form-group  {
      margin-bottom: 10px;
      label {
        margin-bottom: 10px;
        font-size: 1.4rem;
        font-weight: bold;
      }
      .form-control {
        background-color: $white;
        border-radius: 4px;
      }  
    }
    
    .reset-filter-wrap {
      text-align: right;
      a {
        font-size: 1.4rem;
        font-weight: 600;
        color: $text;
        text-decoration: underline;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}