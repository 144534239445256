.dashboard-navigation {
  margin-bottom: 30px;
  ul {
    list-style: none;
    padding-left: 0;
    li {
      a {
        background-color: $bg-light2;
        color: $text;
        margin-bottom: 10px;
        padding: 15px 20px;
        border-radius: 4px;
        font-weight: 600;
        display: block;
        &:hover {
          background-color: $primary;
          text-decoration: none;
          color: $white;
        }
      }
      &.is-active {
        a {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}