.btn {
  font-size: 1.2rem;
  font-weight: bold; 
  text-decoration: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  transition: all 0.3s;
  align-items: center;
  -webkit-appearance: none;
  padding: 15px 30px;
  color: $text;
  background-color: $white;
  text-transform: uppercase;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid transparent;
  [class^="icon-"], [class*=" icon-"] {
    line-height: 1;
  }
  &.btn-right-icon {
    padding: 8px 20px;
    [class^="icon-"], [class*=" icon-"] {
      margin-left: 7px;
      font-size: 1.8rem;
    }
  }
  &.btn-left-icon {
    padding: 8px 20px;
    [class^="icon-"], [class*=" icon-"] {
      margin-right: 7px;
      font-size: 1.8rem;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    transform: scale(1.05);
    text-decoration: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &.btn-full {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  &.btn-primary {
    color: $white;
    background: $primary;
  } 
  &.btn-primary-border {
    color: $primary;
    background: transparent;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  &.btn-white {
    color: $text;
    background: $white;
  }  
  &.btn-black {
    color: $white;
    background: $bg-dark;
  }  
  @include media-breakpoint-up(lg) { 
    font-size: 1.4rem;
    &.btn-right-icon {
      padding: 11px 22px;
      [class^="icon-"], [class*=" icon-"] {
        margin-left: 10px;
        font-size: 1.8rem;
      }
    }
    &.btn-left-icon {
      padding: 11px 22px;
      [class^="icon-"], [class*=" icon-"] {
        margin-right: 10px;
        font-size: 1.8rem;
      }
    }
  }
}


button.close {
  background: transparent;
  border: none;
}
div.nsl-container-block .nsl-container-buttons a {
  max-width: 100% !important;
}
div.nsl-container .nsl-button {
  width: 100%;
  border-radius: 27px !important;
  padding: 5px 0;
}