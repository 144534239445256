

.quantity {
  position: relative;
  /* [name="quantity"] {
    width: 0; 
    height: 0; 
    opacity: 0; 
    padding: 0; 
    border: none; 
    position: absolute;
    visibility: hidden;
  } */
  .pseudo-input-countbtn {
    display: flex;
    flex-direction: row;
    border: 1px solid $text;
    border-radius: 8px;
    width: fit-content;
    span {
      width: 46px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: text;
      cursor: pointer;
      &.minus {
        border-right: 1px solid $text;
      }
      &.plus {
        border-left: 1px solid $text;
      }
      @include media-breakpoint-up(lg) { 
        width: 54px;
      }
    }
    .order-item-count {
      width: 52px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: text;
      //font-size: toRem(18);
      font-size: 1.8rem;
      input {
        border: none;
        width: 100%;
        text-align: center;
        appearance: none;
        outline: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      @include media-breakpoint-up(lg) { 
        width: 84px;
      }
    }
  }  
}