html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  position: relative;
  &.o-hidden {
    overflow: hidden;
  }
  &.o-hidden-all {
    overflow: hidden;
  }
}

.bg-gray {
  background-color: rgba($color: $gray-light, $alpha: 0.5);
}

.bg-gray-light {
  background-color: $gray-light;
}

img {
  height: auto;
  max-width: 100%;
}



abbr[title], abbr[data-original-title] {
  text-decoration: none;
}

.cc-window {
  .cc-btn {
    background-color: $primary !important;
    color: $white !important; 
  }
}

.c-black {
  color: $black !important;
}
.c-white {
  color: $white !important;
}
.c-primary {
  color: $primary !important;
}