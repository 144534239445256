@for $i from 1 through 100 {
    .mb-#{$i} {
      margin-bottom: #{$i}px;
    }
    .mt-#{$i} {
      margin-top: #{$i}px;
    }
    .my-#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }
    .pb-#{$i} {
      padding-bottom: #{$i}px;
    }
  }