@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';
@import "~bootstrap/scss/forms";
.form-group {
    margin-bottom: 16px;
    float: none !important;
}
.form-check-label {
    cursor: pointer;
}


//old
.form-group {
 /*  margin-bottom: 20px;
  position: relative; */
  //height: 48px;
  //overflow: hidden;
  &--textarea {
    //height: 99px;
  }
 /*  .form-control-label, label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 15px;
    z-index: 0;
  } */
  /* .form-control, .select2-container {
    //top: 12px;
    padding: 12px 15px;
    background: #F8F8F8;
    border: none;
    font-weight: normal;
    height: auto;
    font-size: 1.4rem;
    border-radius: 4px;
    z-index: 1;
    &.select2-container--default .select2-selection--single {
      border: none;
      position: relative;
      background: transparent;
      .select2-selection__arrow {
        top: 0;
      }
    }
  } */
  &--error {
    border-color: $red;
    border-width: 2px;
    .form-control-label {
      color: $red;
    }
  }
  .error, .be-error {
    color: $red !important;
    font-size: 1.4rem !important;
    margin-bottom: 0 !important;
    text-align: left !important;
    line-height: 1.5 !important;
    display: none;
  }
  .be-succes {
    color: $green !important;
    font-size: 1.4rem !important;
    margin-bottom: 0 !important;
    text-align: left !important;
    line-height: 1.5 !important;
    display: none;
  }
  .form-control-wrap {
    position: relative;
    padding: 0;
    background: transparent;
    border: none;
    //height: 48px;
    //overflow: hidden;
    &--textarea {
      //height: 99px;
    }
   /*  .form-control-label {
      position: absolute;
      font-size: 1.4rem;
      font-weight: 400;
      top: 14px;
      left: 15px;
      transition: all 0.3s;
      margin-bottom: 0;
      color: $black;
      z-index: 2;
      white-space: nowrap;
      &--shrink {
        top: 2px;
        font-size: 1rem;
        background-color: transparent;
        padding: 0 3px 0 0;
        
      }
    }
    .form-control {
      //position: absolute;
      left: 0;
      right: 0;
      //top: 12px;
      padding: 17px 15px 12px;
      border: none;
      color: $text;
      font-weight: bold;
      z-index: 1;
      position: relative;
      &[type="file"] {
        visibility: hidden;
        width: 1px;
        height: 1px;
        opacity: 0;
      }
    } */
    &--error {
      border-color: $red;
      border-width: 2px;
      .form-control-label {
        color: $red;
      }
    }
  }
}

/* .form-group {
  .form-check {
    padding-left: 0;
    .form-check-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.4rem;
      font-weight: normal;
      cursor: pointer;
      .notice {
      }
      p {
        display: inline-block;
        font-size: 1.4rem;
        margin-bottom: 0;
      }
      .pseudo-check {
        width: 22px;
        flex: 0 0 22px; 
        height: 22px;
        background-color: transparent;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        i {
          display: none;
        }
        &-square {
          border-radius: 0;
        }
      }
    }
    .form-check-input, input[type="radio"] {
      margin: 0;
      top: -5px;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      
      &:checked {
        &~ .form-check-label {
          .pseudo-check {
            background-color: $primary;
            color: $white;
            border-color: $primary;
            i {
              display: block;
            }
          }
        }
      }
    }
  }
}
.form-check {
  display: flex;
  padding-left: 30px;
  .form-check-input {
    width: 15px;
    height: 15px;
    margin-left: -30px;
  }
  label {
    margin-bottom: 0;
  }
} 
.input-gropu {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
} */

.password-field {
  position: relative;
  .pass-eye {
    position: absolute;
    right: 25px;
    top: 35px;
    cursor: pointer;
  }
}

form {
  margin-bottom: 30px;
}

span.wpcf7-list-item {
  margin: 0;
}


.range-wrap {
  .irs--big .irs-line {
    height: 4px;
    top: 15px;
    border: none;

    background-color: #C6C6C6;
  }
  .irs--big .irs-bar {
    border: none;
    top: 15px;
    background-color: $black;
    background: $black;
    height: 4px;
  }
  .irs--big .irs-handle {
    border: none;
    top: 7px;
    background: none;
    background-color: $white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    width: 20px;
    height: 20px;

  }
  .irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single {
    background: transparent;
    color: $text;
    border: none;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 1px;
    top: 29px;
  }
  .irs--big .irs-from {
    transform: translateX(40%);
  }
  .irs--big .irs-to {
    transform: translateX(-40%);
  }
  .irs--big .irs-min, .irs--big .irs-max {
    display: none;
  }
}