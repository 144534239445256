.hp-treephotos {
  margin: 15px 0;
  .row {
    margin-left: -10px;
    margin-right: -10px;
    >div {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .treephoto-item-wrap {
    margin-bottom: 12px;
    position: relative;
    .image-wrap {
      height: 400px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
      @include media-breakpoint-up(xl) { 
        height: 500px;
      }
      @include media-breakpoint-up(xxl) { 
        height: 613px;
      }
    }
    .content-wrap {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      background: transparent linear-gradient(180deg, #00000000 0%, #00000099 100%) 0% 0% no-repeat padding-box;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      .title {
        letter-spacing: 0px;
        color: $white;
        text-shadow: 0px 3px 6px #0000005E;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 33px;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 1.4rem;
        letter-spacing: 0px;
        line-height: 21px;
        color: $white;
        text-shadow: 0px 3px 6px #0000005E;
        margin-bottom: 30px;
      }
      .btn {
        width: fit-content;
      }
      @include media-breakpoint-up(xl) { 
        padding: 30px;
        .title {
          font-size: 2.6rem;
          line-height: 39px;
        }
        .desc {
          font-size: 1.6rem;
          line-height: 25px;
        }
      }
    }
  }
}