.breadcrumbs-section {
  background-color: $gray-light;
  padding: 20px 0;
  p {
    margin-bottom: 0;
    text-align: center;
    a, .breadcrumb_last {
      text-transform: uppercase;
      color: $gold;
    }
    a {
      color: $text;
      &:hover {
        text-decoration: none;
      }
    }
  }
}