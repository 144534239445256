.modal-backdrop {
  z-index: 104000;
}
.modal {
  z-index: 105000;
}
.modal-dialog {
  max-width: 800px;
  .modal-content  {
    padding: 30px 10px;
    @include media-breakpoint-up(md) { 
      padding: 30px 30px;
    }
    @include media-breakpoint-up(lg) { 
      padding: 50px 30px;
    }
  }
}


//new
.swal2-popup {
  border-radius: 4px !important;
  color: $black !important;
  width: 420px !important;
  .swal2-close {
    border: none;
    outline: none;
    background: transparent;
    //font-size: toRem(24);
    font-size: 1.8rem;
    color: $black;
    position: absolute;
    right: 32px;
    top: 28px;
    &:focus {
      box-shadow: none;
    }
  }
}
.swal2-html-container {
  margin: 0 !important;
  text-align: left !important;
 
}
div:where(.swal2-container) .swal2-popup .swal2-html-container {
   padding: 0;
}
.modal-content {
  border-radius: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border: none;
  .modal-header {
    border: none;
    padding: 32px 32px 16px;
    h5 {
      //font-size: toRem(18);
      font-size: 1.8rem;
    }
    .close {
      border: none;
      outline: none;
      background: transparent;
      //font-size: toRem(24);
      font-size: 1.8rem;
    }
  }
  .modal-body {
    padding: 0 32px;
  }
  .modal-footer {
    border: none;
    padding: 16px 32px 32px;
  }
}


.toastify {
  background: $white !important;
  padding: 16px !important;
  border-radius: 4px !important;
  max-width: 362px !important;
  .toast-close {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 10px;
    line-height: 1;
    color: $text;
    font-size: 0;
    opacity: 1;
    &::before {
      content: "\ea0f"; /* Unicode pre znak X */
      color: $text;
      font-family: "icomoon" !important;
      font-size: 1.0rem;
    }
  }
}

.toast-content {
  .toast-header {
    margin-bottom: 8px;
    h5 {
      font-size: 1.4rem;
      color: $text;
    }
  }
}

