.related-products {
  padding: 60px 0 100px;
  background-color: $bg-light2;
  h2 {
    letter-spacing: 6.6px;
    margin-bottom: 20px;
  }
  p {
    letter-spacing: 0.7px;
    line-height: 32px;
    color: rgba($color: #222, $alpha: 0.5);
    margin-bottom: 50px;
  }
  .swiper {
    position: relative;
  }
  .swiper-slide {
    width: 230px;
  }
  .swiper-pagination, .swiper-button-next, .swiper-button-prev {
    display: none;
  }
  @include media-breakpoint-up(md) { 
    .swiper-slide-prev {
      opacity: 0.5;
    }
    .swiper {
      padding-left: calc((100vw - 720px) / 2);
      padding-right: calc((100vw - 720px) / 2);
      //width: 720px;
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 100px 0;
    .swiper {
      padding-left: calc((100vw - 960px) / 2);
      padding-right: calc((100vw - 960px) / 2);
    }
    .swiper-button-next {
      width: 54px;
      height: 50px;
      z-index: 2;
      border-radius: 4px;
      background-color: $bg-dark2;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        font-size: 1.5rem;
        color: $white;
      }
    }
    .swiper-button-prev {
      width: 54px;
      height: 50px;
      z-index: 2;
      border-radius: 4px;
      background-color: $bg-dark2;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after {
        font-size: 1.5rem;
        color: $white;
      }
    }
    .swiper-pagination {
      display: block;
      position: static;
      margin-top: 30px;
      .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background-color: rgba($color: $bg-dark2, $alpha: 0.3);
        margin: 0 5px;
        &.swiper-pagination-bullet-active {
          background-color: $bg-dark2;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) { 
    .swiper {
      padding-left: calc((100vw - 1140px) / 2);
      padding-right: calc((100vw - 1140px) / 2);
      //width: 1140px;
    }
    .swiper-button-next {
      right: 4%;
    }
    .swiper-button-prev {
      left: 4%;
    }
  }
  @include media-breakpoint-up(xxl) { 
    .swiper {
      padding-left: calc((100vw - 1400px) / 2);
      padding-right: calc((100vw - 1400px) / 2);
      //width: 1140px;
    }
  }
}