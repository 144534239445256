.cwginstock-subscribe-form {
  .panel-primary {
    border: none;
    background-color: #000000;
    border-radius: 4px;
    padding: 30px 20px;
    margin-bottom: 40px;
    >.panel-heading {
      background-color: transparent;
      border: none;
      color: $black;
      padding: 0;
      h4 {
        color: $white;
        font-size: 1.8rem;
        line-height: 28px;
        margin-bottom: 15px;
      }
      p {
        color: $white;
        font-size: 1.4rem;
        margin-bottom: 20px;
        line-height: 28px;
      }
    }
  }
  .panel-body  {
    padding: 0;
  }
  .cwgstock_button {
    padding: 15px 30px;
    width: auto;
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    background-color: $white;
    margin-bottom: 15px;
  }
  .cwgstock_output {
    font-size: 1.6rem;
  }
}

#cwg-bis-overlay {
  z-index: 1000000;
}