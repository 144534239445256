.main-footer {
  background-color: $bg-dark2;
  .top-footer {
    padding: 40px 0 0;
    .footer-menu-item {
      margin-bottom: 20px;
    }
    h5 {
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: rgba($color: $white, $alpha: 0.5);
    }
    ul, li, p, .link {
      color: $white;
      font-size: 1.4rem;

    }
    .nav-link {
      color: $white;
      font-size: 1.4rem;
    }
    .link {
      display: block;
      i {
        margin-right: 5px;
      }
    }
    .form-control {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
    .mce_inline_error {
      color: $primary;
    }
    @include media-breakpoint-up(md) { 
      padding-top: 80px;
    }
  }

  .bottom-footer {
    padding: 30px 0;
    .navbar-brand {
      margin-bottom: 20px;
      max-width: 32px;
      display: block;
      margin-bottom: 16px;
    }
    p {
      font-size: 1.2rem;
      color: rgba($color: $white, $alpha: 0.5);
      margin-bottom: 0;
    }
    @include media-breakpoint-up(md) { 
      padding-bottom: 50px;
      .col-12 {
        display: flex;
        align-items: center;
        p, .navbar-brand {
          margin-bottom: 0;
        }
        .navbar-brand {
          margin-right: 30px;
        }
      }
    }
  }


  .top-panel {
    background-color: $black;
    padding: 30px 0;
    .top-panel-item {
     font-size: 1.3rem;
     color: rgba($color: $white, $alpha: 0.5); 
     display: flex;
     justify-content: center;
     align-items: center;
     i {
       color: $primary;
       margin-right: 10px;
      }
      span {
        color: $white;
        text-transform: uppercase;
      }
    }
    @include media-breakpoint-up(md) { 
      .top-panel-item {
        justify-content: flex-start;
      }
    }
  }
}

.ec-v-form-holder {
  width: auto !important;
}
.ec-v-form-step {
  height: auto !important;
  .ec-v-forms-label {
    color: $white !important;
  }
  .ec-v-form-input, .ec-v-form-button, .ec-v-form-submit {
    padding: 0 0 10px 0 !important;
  }
  .ec-v-form-text {
    padding: 0 !important;
    h2 {
      color: $white !important;
      font-size: 20px !important;
      span {
        font-family: inherit !important;
      }
    }
  }
}