.main-header {
  box-shadow: 0px 2px 10px #0000001A;
  .top-menu {
    background-color: $bg-light2;
    position: fixed;
    padding: 10px 0;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s;
    z-index: 999;
    display: none;
    a {
      color: $text;
      margin-left: 10px;
      font-size: 1.4rem;
      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }
    &.scroll {
      transform: translateY(-100%);
    }
    @include media-breakpoint-up(lg) { 
      display: block;
    }
    @include media-breakpoint-up(xl) { 
      .top-menu-items {
        margin-right: 5%;
      }
      
    }
  }
  .main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s;
    background: $white;
    z-index: 999;
    @include media-breakpoint-up(lg) { 
      top: 42px;
    }
    &.scroll {
      box-shadow: 0px 2px 10px #0000001A;
      top: 0;
      @include media-breakpoint-up(lg) { 
        .navbar {
          padding: 16px 0;
        }
      }
    }
  }
  .navbar {
    padding: 14px 0px;
    @include media-breakpoint-up(lg) { 
      transition: all 0.3s;
      padding: 20px 0;
    }
  }
  .navbar-brand {
    padding: 0;
    @include media-breakpoint-up(xl) { 
      margin-left: 5%;
    }

  }

  .navbar-nav  {
    margin: 30px 0;
    @include media-breakpoint-up(lg) { 
      margin: 0;
    }
  }

  .nav-item {
    position: relative;
    .tag {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
      background: $primary;
      color: $white;
      font-size: 0.9rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 2px 5px;
    }

    .nav-link {
      color: $text;
      font-size: 1.4rem;
    }
    &.active {
      >.nav-link {
        color: $pink-dark;
      }
    }
    @include media-breakpoint-up(lg) { 
      margin: 0;
      .nav-link {
        padding: 5px 10px;
        font-size: 1.3rem;
        &:hover {
          color: $pink-dark;
        }
      }
      &.active {
        position: relative;
        >.nav-link {
          color: $text;
        }
        &::after {
          height: 3px;
          width: 30px;
          background-color: $pink-dark;
          content: '';
          position: absolute;
          left: 10px;
          bottom: -1px;
        }
      }
    }
    @include media-breakpoint-up(xl) { 
      .nav-link {
        padding: 5px 15px;
        font-size: 1.4rem;
      }
      &.active {
        &::after {
          left: 15px;
        }
      }
    }
  }

  .sub-menu {
    list-style: none;
    display: none;
    padding: 10px 30px;
    background-color: $bg-light2;
    min-width: 200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
    position: absolute;
    border-radius: 4px;
    top: 100%;
    left: 0;
    z-index: 1000;
    .nav-link {
      padding: 10px 0px;
      font-size: 1.4rem;
      font-weight: 400;
      color: rgba($color: $text, $alpha: 0.5);
    }
    .nav-item.active {
      >.nav-link {
        font-weight: bold;
        color: rgba($color: $text, $alpha: 0.5)
      }
      &:after {
        display: none;
      }
    }
  }

  .has-children {
    position: relative;
    display: flex;
    >.nav-link {
      padding-right: 30px;
    }
    .show-submenu {
      position: absolute;
      right: 12px;
      height: 100%;
      display: flex;
      align-items: center;
      &::after {
        content: '\f107'; 
        font-family: 'icomoon';
      }
    }
    &:hover {
      .sub-menu {
        display: block;
      }
    }
  }
  .megamenu {
    @include media-breakpoint-up(lg) { 
      >.sub-menu {
        display: none;
        padding: 20px 10px;
        min-width: 120px;
        position: absolute;
        z-index: 1;
        align-items: flex-start;
        //flex-wrap: wrap;
        >.nav-item {
          padding: 0;
          width: 100%;
          flex: 0 0 25%;
          max-width: 25%;
          padding: 0 10px;
          .sub-menu {
            display: block;
            flex: 0 1 100%;
            position: static;
            box-shadow: none;
            padding: 5px 0px;
            margin: 0 20px;
            border-top: 1px solid rgba($color: #333, $alpha: 0.1);
            min-width: 0;
            .nav-item {
              &.active {
                >.nav-link {
                  color: rgba($color: $text, $alpha: 0.5);
                  font-weight: bold;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
          &.category {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            min-width: 180px;
            @include media-breakpoint-up(xl) { 
              min-width: 200px;
            }
            .tag {
              left: 38px;
              bottom: 95%;
              transform: none;
              background: $black;
              text-transform: none;
            }
            img {
              display: block;
              max-width: 23px;
              max-height: 23px;
              margin-top: 13px;
              margin-right: 20px;
            }
            >.nav-link {
              cursor: default;
              flex: 1 0 0;
              font-size: 1.4rem;
              color: $text;
              font-weight: bold;
              padding: 15px 20px;
            }
            .show-submenu {
              display: none;
            }
          }
          &.active {
            &:after {
              display: none;
            }
          }
        }
        
      }
      &:hover {
        .sub-menu {
          display: flex;
        }
      }
    }
  }

  .right-side-menu {
    position: absolute;
    top: 14px;
    right: 15px;
    width: auto;
    display: flex;
    align-items: center;
    height: 27px;
    @include media-breakpoint-up(lg) { 
      position: relative;
      height: auto;
      top: initial;
      right: initial;
    }
    @include media-breakpoint-up(xl) { 
      margin-right: 5%;
    }
  }

  .mobile-menu {
    padding: 0;
    padding-left: 15px;
    @include media-breakpoint-up(lg) { 
      display: none;
    }
  }

  .main-search-btn {
    padding: 0 15px;
    color: $text;
    font-size: 1.5rem;
    position: relative;
    cursor: pointer;
    .search-wrap {
      position: absolute;
      right: -500px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $bg-light2;
      padding: 10px 15px;
      box-shadow: 0px 4px 3px #00000029;
      border-radius: 8px;
      transition: all 0.3s;
      z-index: 99;
      .search-form {

        margin-bottom: 0;
        display: flex;
        align-items: center;
        .form-control {
          border: none;
          width: 120px;
          background: transparent;
          margin: 0 5px;
          font-size: 1.2rem;
        }
      }
      .search-results {
        border-radius: 0px 0px 8px 8px;
        background-color: $bg-light2;
        box-shadow: 0px 4px 3px #00000029;
        padding: 20px 15px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        .search-result-category-wrap {
          margin-bottom: 20px;
          .search-result-category-title {
            color: $text;
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 8px;
            text-transform: uppercase;
          }
          .search-result-category-item {
            font-size: 1.2rem;
            color: #999999;
            span {
              color: $text;
            }
          }
        }
        .search-result-products-wrap {
          .search-result-products-title {
            color: $text;
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 8px;
            text-transform: uppercase;
          }
          .search-result-products-title-empty {
            color: $text;
            font-size: 1.2rem;
          }
          .search-result-products-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 40px;
              max-height: 40px;
              margin-right: 10px;
            }
            span {
              font-size: 1.2rem;
              color: $text;
            }
          }
          .search-more-products {
            margin-top: 20px;
            text-align: center;
            a {
              color: $text;
              font-size: 1.2rem;
              font-weight: bold;
            }

          }
        }
      }
      &.show {
        right: 0;
      }
      &.has-result {
        border-radius: 8px 8px 0px 0px;

      }
      @include media-breakpoint-up(lg) { 
        .search-form {
          .form-control {
            width: 190px;
          }
        }
      }
    }
    &:hover {
      text-decoration: none;
    }
  }

  .btn-basketitems {
    position: relative;
    cursor: pointer;
    .icon-angle-down {
      margin-right: 0;
      font-size: 1.4rem;
    }
  }
  .cartbutton-wrap {
    position: relative;
    .btn {
      .cart-count-wrap {
        position: relative;
        .cart-count {
          position: absolute;
          top: -6px;
          right: -4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: $red;
          color: $white;
          font-size: 1.0rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .woocommerce-Price-amount {
        font-size: 1.2rem;
        margin: 0 4px;
      }
    }
  }

  .basket-items-popup-wrap {
    position: absolute;
    display: none;
    top: 100%;
    right: -30px;
    width: 408px;
    background: $white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 12px;
    z-index: 3;
    text-align: left;
    max-width: calc(100vw - 30px);
    .basket-items-header {
      font-size: 1.8rem;
      font-weight: 600;
      font-family: 'Poppins';
      margin-bottom: 16px;
    }
    .basket-popup-notice {
      background: $info-light;
      border-radius: 4px;
      text-align: center;
      font-size: 1.2rem;
      color: $info-normal;
      padding: 6px;
      margin-top: 16px;
      font-weight: normal;
      display: flex;
      i {
        font-size: 1.6rem;
        margin-right: 10px;
      }
    }
    .basket-items-content {
      border: 1px solid $gray-200;
      border-radius: 4px;
      max-height: 300px;
      overflow: auto;
    }
    .basket-order-item {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border-bottom: 1px solid $gray-200;
      &:last-of-type {
        border: none;
      }
      .image-wrap {
        border-radius: 4px;
        width: 40px;
        height: 45px;
        overflow: hidden;
        margin-right: 12px;
        img {
          @include objetfit;
        }
    
      }
      .basket-order-item-name {
        margin-right: auto;
        font-size: 1.4rem;
        max-width: 140px;
        padding-right: 10px;
      }
      .basket-order-item-footer {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .basket-order-item-price {
        color: $primary;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
        .price-piece {
          font-size: 1.2rem;
          color: $gray-700;
        }
      }
      .basket-order-item-remove {
        font-size: 1.0rem;
        margin-left: 12px;
        margin-right: 0;
        color: $gray-700;
        cursor: pointer;
        padding: 8px;
        background-color: $gray-100;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 0;
        }
      }
    }
    .total-price-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 16px 0;
      .basket-order-price-item-name {
        font-weight: 500;
      }
      .basket-order-price-item-price {
        .woocommerce-Price-amount {
          font-size: 2.4rem !important;
          font-weight: 500;
          color: $primary;  
          margin: 0 !important;
        }
        
      }
    }
    .basket-items-footer {
      .btn {
        width: 100%;
      }
    }
    @include media-breakpoint-up(md) {
      right: 0;
      padding: 24px;
    }
  
  }
  .top-panel {
    background-color: $bg-dark2;
    padding: 10px 0;
    margin-top: 55px;
    .top-panel-item {
     font-size: 1.2rem;
     color: rgba($color: $white, $alpha: 0.5); 
     display: flex;
     justify-content: center;
     align-items: center;
     i {
       color: rgba($color: #F8F8F8, $alpha: 0.3);
       margin-right: 10px;
      }
      span {
        color: $white;
        text-transform: uppercase;
      }
    }
    @include media-breakpoint-up(md) { 
      .top-panel-item {
        justify-content: flex-start;
      }
    }
    @include media-breakpoint-up(lg) { 
      padding: 15px 0;
      margin-top: 136px;
      .top-panel-item {
        font-size: 1.3rem;
      }
    }
  }
}

