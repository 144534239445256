.hp-colection {
  .hp-colection-item {
    width: 100%;
    .image-wrap {
      width: 100%;
      min-height: 250px;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        object-position: center;
        max-width: initial;
        width: 100%;
        height: 100%;
      }
    }
    .colection-content {
      padding: 50px 15px;
      background-color: #F8F8F8;
      .colection-subtitle {
        margin-bottom: 5px;
        font-size: 1.2rem;
        color: rgba($color: $text, $alpha: 0.5);
        text-transform: uppercase;
        letter-spacing: 3.6px;
      }
      .colection-title {
        margin-bottom: 10px;
        font-size: 2.2rem;
        line-height: 33px;
        letter-spacing: 6.6px;
      }
    }
    @include media-breakpoint-up(md) { 
      display: flex;
      flex-direction: row;
      .image-wrap {
        flex: 0 0 50%;
        width: 50%;
      }
      .colection-content {
        flex: 0 0 50%;
        width: 50%;
        padding: 80px 50px;
      }
      &:nth-child(even) {
        .colection-content {
          padding-right: calc(((100vw - 720px) /2) + 15px);
        }
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        .colection-content {
          padding-left: calc(((100vw - 720px) /2) + 15px);
        }
      }
    }
    @include media-breakpoint-up(lg) { 
      .colection-content {
        padding: 150px 80px;
        .colection-subtitle {
          margin-bottom: 8px;
          font-size: 1.6rem;
          letter-spacing: 4.8px;
        }
        .colection-title {
          margin-bottom: 30px;
          font-size: 3.6rem;
          line-height: 55px;
          letter-spacing: 10.8px;
        }
      }
      &:nth-child(even) {
        .colection-content {
          padding-right: calc(((100vw - 960px) /2) + 15px);
        }
      }
      &:nth-child(odd) {
        .colection-content {
          padding-left: calc(((100vw - 960px) /2) + 15px);
        }
      }
    }
    @include media-breakpoint-up(xl) { 
      .colection-content {
        padding: 150px 120px;
        
      }
      &:nth-child(even) {
        .colection-content {
          padding-right: calc(((100vw - 1140px) /2) + 15px);
        }
      }
      &:nth-child(odd) {
        .colection-content {
          padding-left: calc(((100vw - 1140px) /2) + 15px);
        }
      }
    }
  }
}