.hp-new-products {
  padding: 60px 0 100px;
  h2 {
      letter-spacing: 6.6px;
    }
  p {
    line-height: 32px;
    color: rgba($color: $text, $alpha: 0.5);
    margin-bottom: 30px;

  }
  @include media-breakpoint-up(lg) { 
    padding:100px 0 150px;
    h2 {
      letter-spacing: 10.8px;
    }
    p {
      margin-bottom: 50px;
    }
    .product-item {
      margin-bottom: 70px;
    }
  }
}