@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';
.nomodal-swal.swal2-popup  {
  padding: 0 !important;
  padding-bottom: 32px !important;
  max-width: 420px !important;
  .swal2-html-container {
    padding: 16px 32px 32px;
    .icon {
      //font-size: toRem(64);
      font-size: 6.4rem;
      text-align: center;
      margin: 64px 0 0;
      color: $red-light;
    }
    .h5 {
      //font-size: toRem(18);
      font-size: 1.8rem;
      text-align: center;
      max-width: 260px;
      margin: 0 auto;
      line-height: 24px;
    }
  }
  .swal2-actions {
    width: 100%;
    padding: 0 32px;
    margin-top: 0;
    justify-content: space-between;
    @include media-breakpoint-up(lg) { 
      .btn {
        min-width: 162px;
        padding: 9px 12px;
      }
    }
  }
}