.product-detail {
  padding: 30px 0 50px;
  .woocommerce-breadcrumb {
    display: none;
  }
  .breadcrumbs {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: $text;
    .devider {
      color: $primary;
    }
    a {
      color: $text;
      font-size: 1.2rem;
      &:hover {
        color: $primary;
      }
    }
  }
  h1 {
    margin-bottom: 30px;
  }
  .price {
    font-size: 2.4rem;
    font-weight: normal;
    color: $text;
    margin-bottom: 30px;
    ins {
      color: $primary;
      font-weight: bold;
      text-decoration: none;
    }
    .woocommerce-price-suffix {
      font-size: 1rem;
      color: #666666;
    }
  }
  .single_variation_wrap {
    margin-top: 15px;
  }
  .available-on-backorder {
    background-color: #F0A075;
    color: $white;
    padding: 20px;
    border-radius: 4px;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .tab-wrap {
    margin: 30px 0;
    border-radius: 4px;
    overflow: hidden;
    .tab-header {
      display: flex;
      .tab-item {
        flex: 0 0 50%;
        width: 50%;
        background-color: #666666;
        color: $white;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 12px;
        text-align: center;
        cursor: pointer;
        &.active {
          background-color: $bg-light2;
          color: $text;
        }
      }
    }
    .tab-content-wrap {
      background-color: $bg-light2;
      .tab-content {
        display: none;
        .tab-text {
          padding: 30px 15px;
          color: #666666;
          font-size: 1.4rem;
          letter-spacing: 0px;
          line-height: 28px;
        }
        &.active {
          display: block;
        }
      }
    }
  }
  

  @include media-breakpoint-up(lg) { 
    padding: 50px 0 100px;
    .product-image-wrap {
      margin-bottom: 30px;
    }
  }
}

.product-mobile-slider {
  .product-image-mobile {
    img {
      width: 100%;
    }
  }
  .swiper-pagination {
    top: 20px;
    left: 15px;
    width: auto;
    display: flex;
    flex-direction: column;
    bottom: initial;
    background-color: $bg-dark2;
    border-radius: 4px;
    padding: 5px;
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: rgba($color: $white, $alpha: 0.6);
      margin: 5px 0;
      &.swiper-pagination-bullet-active {
        background-color: $white;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}