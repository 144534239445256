.woocommerce-pagination {
  display: block;
  width: 100%;
  padding: 30px 0;
  text-align: center;
  .page-numbers {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    li {
      span, a {
        width: 30px;
        height: 30px;
        color: $text;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }
      a {
        color: $primary;
        &:hover {
          text-decoration: none;
        }
        &.next, &.prev {
          background-color: $bg-dark;
          color: $white;
        }
      }
    }
  }
}