@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/forms/form-select";
@import './../base/variables';
@import './../helpers/mixins';
@import './../helpers/pseudo-input-countbtn';
@import './../product/product-single-price';

//@import "~bootstrap/scss/modal";

.swal2-popup.add-to-cart {
  width: 620px !important;
  padding: 0 !important;
  border-radius: 12px !important;
  h5.c-secondary {
    color: $secondary;
  }
  .modal-footer {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    .btn {
      width: 100%;
      
      &:first-child {
          margin-top: 15px;
      }
    }
    @include media-breakpoint-up(md) { 

      .btn {
        width: auto;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}


.product-modal-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .image-wrap {
    border-radius: 4px;
    width: 40px;
    height: 44px;
    flex: 0 0 40px;
    overflow: hidden;
    margin-right: 12px;
    img {
      @include objetfit;
    }
    @include media-breakpoint-up(lg) { 
      width: 62px;
      flex: 0 0 62px;
      height: 70px;
      margin-right: 15px;
    }
  }
  .title {
    font-weight: 500;
    margin-right: auto;
    color: $text;
    flex: 1 0 0;
    font-size: 1.2rem;
    @include media-breakpoint-up(lg) { 
      font-size: 1.6rem;
    }
  }
  .quantity {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .price {
    color: $primary;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 20px;
    @include media-breakpoint-up(lg) { 
      font-size: 1.8rem;
      margin-left: 45px;
    }
  }
}

.product-modal-item-multi {
  padding-bottom: 32px;
  .ean-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px;
    .stock-wrap {
      .wc_bis_product_form_lemon {
        display: none;
      }
    }
    .stock {
      margin-bottom: 0;
      padding: 6px 12px;
      border-radius: 30px;
      font-size: 1.2rem;
      font-weight: 500;
      &.in-stock {
        background-color: $green-50;
        color: $green;
      }
      &.out-of-stock {
        background-color: $gray-100;
        color: $secondary;
      }
      &.in-stock-low {
        background-color: $red-100;
        color: $red;
      }
    }
  }
  .add-item-wrap-form {
    margin: 16px 0 0;
    form.simple_form, .variations_button {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      .single_add_cart_lemon {
        i {
          font-size: 2.4rem;
        }
      }
      .btn {
        min-width: 166px;
        flex-grow: 1;
      }
    }
    form.variations_form {
      .reset_variations {
        display: block;
        text-align: right;
      }
    }
  }
  @include media-breakpoint-up(xl) { 
    .add-item-wrap-form {
      form.simple_form, .variations_button {

        .btn {
          min-width: 240px;
        }
      }
    }
  }
}

.product-modal-notice {
  background: $info-light;
  border-radius: 4px;
  text-align: center;
  font-size: 1.2rem;
  color: $info-normal;
  padding: 6px 12px;
  display: flex;
  text-align: center;
  line-height: 18px;
  @include media-breakpoint-up(lg) { 
    justify-content: center;
  }
  i {
    font-size: 1.6rem;
    margin-right: 10px;
  }
}

.product-toast-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .image-wrap {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    flex: 0 0 40px;
    overflow: hidden;
    margin-right: 12px;
    img {
      @include objetfit;
    }
    @include media-breakpoint-up(lg) { 
      width: 45px;
      flex: 0 0 45px;
      height: 45px;
      margin-right: 15px;
    }
  }
  .title {
    margin-right: auto;
    color: $text;
    flex: 1 0 0;
    font-size: 1.2rem;
    @include media-breakpoint-up(lg) { 
      font-size: 1.4rem;
    }
  }
  .quantity {
    font-size: 1.4rem;
    color: $text;
    margin-left: 20px;
  }
  .price {
    color: $primary;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 20px;
    @include media-breakpoint-up(lg) { 
      font-size: 1.4rem;
      margin-left: 20px;
    }
  }
}