h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  color: $text;

}

h1, .h1 {
  font-size: 2.4rem;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 30px;
  @include media-breakpoint-up(lg) { 
    font-size: 2.4rem;
  }
}

h2, .h2 {
  font-size: 2.2rem;
  margin: 0;
  margin-bottom: 30px;
  font-weight: bold;
  line-height: 1;
  @include media-breakpoint-up(md) { 
    font-size: 2.9rem;

  }
  @include media-breakpoint-up(lg) { 
    font-size: 3.6rem;
  }
}

h3, .h3 {
  font-size: 2.5rem;
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
  @include media-breakpoint-up(md) { 
    font-size: 3rem;
  }
  @include media-breakpoint-up(lg) { 
    font-size: 3.2rem;
  }
}

h4, .h4 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 1.2;
}

body {
  color: $text;
  font-size: 1.5rem;
  @include media-breakpoint-up(md) { 
    font-size: 1.6rem;
  }
}

p, ul {
  font-size: 1.4rem;
  margin: 0 0 15px;
  font-weight: normal;
  @include media-breakpoint-up(md) { 
    font-size: 1.6rem;
  }
}

table {
  color: $text;
}

td, th {
  font-size: 1.4rem;
  font-weight: normal;
  padding: 2px;
  @include media-breakpoint-up(md) { 
    font-size: 1.6rem;
  }
}

a {
  font-size: 1.4rem;
  color: $primary;
  transition: all 0.3s;
  @include media-breakpoint-up(md) { 
    font-size: 1.6rem;
  }
}

small {
  font-size: 1rem;
}