.product-single-price-wrap {
  margin: 16px 0 24px;
  padding: 0 24px;
  background: $gray-200;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  .product-single-price-item {
    padding: 24px 0;
    flex: 1 0 auto;
    &:nth-child(2) {
      border-top: 1px solid $blue-100;
    }
  }
  .prsing-price-header {
    font-weight: 500;
    font-size: toRem(14);
    margin-bottom: 7px;
  }
  .prsing-sale-price {
    display: flex;
    align-items: center;
    del {
      font-size: toRem(14);
    }
    .badge {
      font-size: toRem(14);
      color: $red;
      margin-left: 5px;
    }
  }
  .prsing-regular-price {
    font-size: toRem(24);
    color: $red;
    font-weight: 500;
    line-height: 44px;
    .sufix {
      font-size: toRem(14);
      color: $text;
      font-weight: 400;
    }
  }
  .prsing-regular-price-tax {
    color: $gray-600;
    font-size: toRem(14);
  }
  @include media-breakpoint-up(lg) { 
    flex-direction: row;
    padding: 24px 0;
    .product-single-price-item {
      padding: 0 24px;
      &:nth-child(2) {
        border-top: none;
        border-left: 1px solid $blue-100;
      }
    }
  }
}